export default class Menu {

  constructor() {
    this.menu = $('.menu-container');
    this.initializedFully = false;

    this.initializedDesktop = false;
    this.initializedMobile = false;

    this.mobileBreakpoint = 1230;
  }

  init() {
    if (window.innerWidth > this.mobileBreakpoint) {
      this.initDesktopMenu();
    } else {
      this.initMobileMenu();
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth > this.mobileBreakpoint && !this.initializedDesktop) {
        this.initDesktopMenu();
      }
      if (window.innerWidth <= this.mobileBreakpoint && !this.initializedMobile) {
        this.initMobileMenu();
      }
    });
  }

  loadFullMenu(successCallback) {
    $.ajax({
      url: '/menu-fetch', // varnish
      dataType: 'json',
      type: 'get',
      success: function(data){
        if (typeof data.success !== 'undefined' && data.success) {
          $('.header-navbar').replaceWith(data.menu_html);

          if (typeof successCallback === 'function') {
            successCallback();
          }
        }
      },
      error: function(jqXhr, textStatus, errorThrown){
        console.log(errorThrown);
      }
    });
  }

  initDesktopMenu() {

    this.initializedDesktop = true;

    let showDelay = 400;
    let hideDelay = 10;
    let menuEnterTimer;
    let menuLeaveTimer;

    var that = this;

    $(document).on('mouseenter', '.level-0 .menu-list-item', function () {
      clearTimeout(menuLeaveTimer);
      clearTimeout(menuEnterTimer);
      var index = $(this).index();

      menuEnterTimer = setTimeout(() => {
        if (!that.initializedFully) {
          that.loadFullMenu(() => {
            that.initializedFully = true;
            $('.menu-list-item').removeClass('active');
            $('body').addClass('js-menu-active');
            $(this).addClass('active');
            $('.menu-list-item.level-0').eq(index-1).trigger('mouseenter');
          });
        } else {
          $('.menu-list-item').removeClass('active');
          $('body').addClass('js-menu-active');
          $(this).addClass('active');
        }
      }, showDelay);
    });

    $(document).on('mouseleave', '.menu-container', function () {
      clearTimeout(menuEnterTimer);
      menuLeaveTimer = setTimeout(() => {
        $('body').removeClass('js-menu-active');
         $('.menu-list-item').removeClass('active');
      }, hideDelay);
    });

    $(document).on('click', '.js-desktop-menu-close', function () {
      $('body').removeClass('js-menu-active');
      $('.menu-list-item').removeClass('active');
    });

    // init
    var tree = [];
    let menuEnterTimer2;
    let enterDelay = 200;
    $(document).on('mouseleave', '.menu-list-item', function () {
      clearTimeout(menuEnterTimer2);
    });
    $(document).on('mouseenter', '.menu-list-item', function () {
      clearTimeout(menuEnterTimer2);
      menuEnterTimer2 = setTimeout(() => {
        var depth = parseInt($(this).closest('.list-container').data('level-wrapper'));
        var categoryId = $(this).data('id-category');
        var recommendedIds = $(this).data('recommended-products')
          ? $(this).data('recommended-products').split(',')
          : []
        ;

        tree[depth] = {
          id: categoryId,
          recommendedIds: recommendedIds,
          depth: depth,
        };
        tree.splice(depth + 1, 4);

        var $wrapper = $(this).closest('.list-container');
        var $nextWrapper = $wrapper.next();

        // show next category
        $wrapper.next().nextAll().html('');
        if ($wrapper.next().children().first().data('id-category-parent') != categoryId) {
          $wrapper.next().html('');
          $('.navbar-list[data-id-category-parent="' + categoryId + '"]')
            .clone()
            .appendTo($nextWrapper)
          ;
        }

        // show products
        var treeCopy = Array.prototype.slice.call(tree);
        for (let category of treeCopy.reverse()) {
          if (category.recommendedIds.length) {
            $wrapper.nextAll().reverse().each(function (index) {
              // set product if empty (no category)
              if (!$(this).html()) {
                var $pr = $('.js-lists-container .navbar-product[data-id-product="' + category.recommendedIds[index] + '"]').first();
                $pr.find('img').each(function () {
                  $(this).attr('src', $(this).attr('data-src'));
                });
                $pr
                  .clone()
                  .appendTo($(this))
                ;
              }
            });
            break;
          }
        }

        // move track
        $wrapper.find('.menu-list-item').removeClass('focus');
        $wrapper.nextAll().find('.menu-list-item').removeClass('focus');
        $(this).addClass('focus');
      }, enterDelay);
    });
  }

  showMobileMenu() {
    $('body').toggleClass('js-menu-mobile');
    $('.mobile-menu-container')
      .addClass('slide-in')
      .removeClass( 'slide-out')
    ;
  }

  hideMobileMenu() {
    $('body').removeClass('js-menu-mobile');
    $('.mobile-menu-container')
      .addClass('slide-out')
      .removeClass( 'slide-in')
    ;
  }

  initMobileMenu() {

    this.initializedMobile = true;

    var that = this;

    if (!that.initializedFully) {
      that.initializedFully = true;
      that.loadFullMenu(() => {
        $('.menu-container .navbar-list.sub-0').not('.navbar-product')
          .detach()
          .appendTo('.mobile-menu-container')
        ;
      });
    }

    $(document).on('click', '.navbar-list--item.has-children:not(.see-all)', function (e) {
      e.preventDefault();

      var categoryId = $(this).data('id-category');

      $(this).closest('.navbar-list')
        .removeClass('slide-in')
        .addClass('slide-out')
      ;

      if ($('.mobile-menu-container .navbar-list[data-id-category-parent="'+categoryId+'"]').length == 0) {
        const $listEl = $('.navbar-list[data-id-category-parent="'+categoryId+'"]').removeClass('slide-in').addClass('slide-out').detach();
        $listEl.appendTo('.mobile-menu-container');
      }

      $('.navbar-list[data-id-category-parent="'+categoryId+'"]')
        .removeClass('slide-out')
        .addClass('slide-in')
      ;

      $('.mobile-menu-container').scrollTop(0);
    });

    $(document).on('click', '.js-mobile-menu-toggle', function (e) {
      e.preventDefault();
      if ($('body').hasClass('varnish-reloaded')) {
        that.showMobileMenu();
      }
    });

    $(document).on('click', '.menu-backdrop, .js-mobile-navbar-close', function (e) {
      e.preventDefault();
      that.hideMobileMenu();
    });


    // mobile back navigation
    // @todo
    var path = [];
    $(document).on('click', '.navbar-list--item', function (e) {
      path.push($(this).closest('ul.navbar-list'));

      var iconClass = $(this).data('icon-class');
      if (iconClass) {
        $('i.icon-category').attr('class', 'icon-category fa ' + iconClass);
      }
    });
    $(document).on('click', '.js-mobile-menu-back-btn', function (e) {
      e.preventDefault();
      $(this).closest('ul.navbar-list')
        .removeClass('slide-in')
        .addClass('slide-out')
      ;
      if (path.length) {
        path.pop()
          .addClass('slide-in')
          .removeClass('slide-out')
        ;
      } else {
        // root
        that.hideMobileMenu();
      }
    });
  }
}
